// App.scss
$app-bg-dark: #191919;
$app-bg-light: #ffffff;
$font-color-dark: #ffffff;
$font-color-light: #000000;
$primary-color: #007BFF;
$hover-color: #0056b3;
$container-bg-dark: #2c2c2c;
$container-bg-light: #f6f6f6;

body {
  margin: 0;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.app {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.dark-mode {
  background-color: $app-bg-dark;
  color: $font-color-dark;
}

.light-mode {
  background-color: $app-bg-light;
  color: $font-color-light;
}

.main-content {
  flex: 1;
}

.navigation-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em 2em;
  box-shadow: 0 2px 10px rgba(25, 25, 25, 0.1);

  .nav-link {
    color: inherit;
    text-decoration: none;
    margin: 0 15px;
    font-size: 1.1em;
    transition: color 0.3s ease;

    &.active {
      color: $primary-color;
    }

    &:hover {
      color: $hover-color;
    }
  }
}

.apple-header {
  font-size: 1.2em;
  margin: 0 auto;
  padding-top: 0.8em;
}

.container {
  background-color: $container-bg-dark;
  border-radius: 20px;
  padding: 15px;
  margin: 2em auto;
  max-width: 600px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 10px;
    margin: 1em;
    width: 90%;
  }
}

.light-mode .container {
  background-color: $container-bg-light;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.toggle-darkmode {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}

.job-section {
  display: flex;
  flex-direction: column;
  align-items: center;

  .modern-input {
    margin-top: 1em;
    padding: 0.8em;
    font-size: 1em;
    border: 1px solid #ddd;
    margin-bottom: 20px;
    border-radius: 5px;
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
    transition: background-color 0.3s ease, border-color 0.3s ease;

    &.dark-mode {
      background-color: #2c2c2c;
      color: $font-color-dark; // Textfarbe für Dark Mode
      border-color: #444;
    }

    &.light-mode {
      background-color: #fff;
      color: $font-color-light; // Textfarbe für Light Mode
      border-color: #ccc;
    }

    &:focus {
      outline: none;
      border-color: $primary-color;
    }
  }

  .submit-button {
    background-color: $primary-color;
    border: none;
    color: #fff;
    padding: 0.8em 1.5em;
    font-size: 1em;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: $hover-color;
    }
  }

  .explanation-text {
    font-size: 0.9em;
    color: #777;
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 1.2em;
  color: $primary-color;
}

.career-info {
  padding: 20px;
  margin-top: 30px;
  border-radius: 10px;

  &.dark-mode {
    background-color: #202020;
    color: $font-color-dark; // Textfarbe für Dark Mode
    border-color: #444;
  }

  &.light-mode {
    background-color: #fff;
    color: $font-color-light; // Textfarbe für Light Mode
    border-color: #ccc;
  }
}

.footer {
  padding: 1em;
  background-color: $app-bg-dark;
  color: $font-color-dark;

  &.dark-mode {
    background-color: $app-bg-dark;
    color: $font-color-dark;
  }

  &.light-mode {
    background-color: $app-bg-light;
    color: $font-color-light;
  }

  a {
    color: inherit;
    text-decoration: none;
    margin: 0 5px;

    &:hover {
      color: $hover-color;
    }
  }

  .info-icon-wrapper {
    position: relative;
    display: inline-flex;
    align-items: center;
    margin-left: 5px;
  
    .info-icon {
      cursor: pointer;
      transition: transform 0.2s ease;
  
      &:hover {
        transform: scale(1.1);
      }
    }
  
    .tooltip-text {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      background-color: rgba(0, 0, 0, 0.75);
      color: white;
      padding: 5px 10px;
      border-radius: 4px;
      font-size: 12px;
      white-space: nowrap;
      transition: opacity 0.2s ease, visibility 0.2s ease;
      z-index: 10;
    }
  
    &:hover .tooltip-text {
      visibility: visible;
      opacity: 1;
    }
  }
  
}
