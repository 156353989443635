// Basis Styling für die gesamte App
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background-color: #191919;
  color: white;
  font-family: Arial, sans-serif;
}

a {
  color: #1e90ff;
  text-decoration: none;
}

