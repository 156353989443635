.gehaelter-entdecken {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
  
    &.dark-mode {
      background-color: #191919;
      color: #ffffff;
    }
  
    &.light-mode {
      background-color: #ffffff;
      color: #000000;
    }
  
    .search-section {
      display: flex;
      align-items: center;
      gap: 20px;
  
      .search-input {
        flex: 2;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
      }
  
      .suggestions-list {
        position: absolute;
        top: 50px;
        left: 0;
        background-color: #ffffff;
        border: 1px solid #ccc;
        z-index: 10;
        list-style: none;
  
        li {
          padding: 10px;
          cursor: pointer;
  
          &:hover {
            background-color: #007bff;
            color: #ffffff;
          }
        }
      }
  
      .region-dropdown {
        flex: 1;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
      }
  
      .view-type {
        display: flex;
        gap: 10px;
  
        .view-button {
          padding: 10px 20px;
          border: 1px solid #ccc;
          border-radius: 5px;
          cursor: pointer;
  
          &.active {
            background-color: #007bff;
            color: #ffffff;
          }
        }
      }
    }
  
    .chart-container {
      margin-top: 20px;
  
      .chart-bar-container {
        display: flex;
        gap: 10px;
  
        .chart-bar {
          flex: 1;
          text-align: center;
  
          .bar {
            height: 100px;
            background-color: #007bff;
            position: relative;
  
            .bar-value {
              position: absolute;
              top: -30px;
              font-size: 14px;
            }
          }
  
          .bar-label {
            margin-top: 10px;
          }
        }
      }
    }
  
    .table-container {
      table {
        width: 100%;
        margin-top: 20px;
        border-collapse: collapse;
  
        th,
        td {
          padding: 10px;
          border: 1px solid #ccc;
          text-align: center;
        }
      }
    }
  }
  