* {
  text-align: left;

}

.datenschutzerklärung-container {
  padding: 2rem;
  line-height: 1.5;

  h2 {
    margin-top: 1rem;
    color: #1e90ff;
  }

  ul {
    list-style-type: disc;
    margin-left: 2rem;
  }

  a {
    color: #1e90ff;
  }
}

