.feedback-form {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 90%;
  max-width: 400px;

  h2 {
    margin-bottom: 15px;
    color: #007BFF;
  }

  form {
    display: flex;
    flex-direction: column;

    input,
    textarea {
      margin-bottom: 10px;
      padding: 10px;
      font-size: 1em;
      border: 1px solid #ddd;
      border-radius: 5px;
      transition: border-color 0.3s ease;

      &:focus {
        outline: none;
        border-color: #007BFF;
      }
    }

    textarea {
      resize: vertical;
      min-height: 100px;
    }

    button {
      background-color: #007BFF;
      border: none;
      color: #fff;
      padding: 10px;
      font-size: 1em;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      margin-top: 10px;

      &:hover {
        background-color: #0056b3;
      }

      &:first-of-type {
        margin-right: 10px;
      }
    }
  }
}

.feedback-form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
} 