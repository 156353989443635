$app-bg-dark: #191919;
$app-bg-light: #ffffff;
$font-color-dark: #ffffff;
$font-color-light: #000000;
$primary-color: #007BFF;
$hover-color: #0056b3;
$container-bg-dark: #2c2c2c;
$container-bg-light: #f6f6f6;

.karrieretipps {
  padding: 2em;

  .tips-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.5em;
  }

  .tip-card {
    background: $container-bg-light; // Passt sich an den Light-Mode an
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 1em;
    text-align: center;
    cursor: pointer; // Zeigt an, dass es anklickbar ist
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  
    &:hover {
      transform: scale(1.05);
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
      border-color: $primary-color; // Visuelle Rückmeldung
    }
  
    &.dark-mode {
      background: $container-bg-dark; // Passt sich an den Dark-Mode an
      border-color: #444;
  
      &:hover {
        border-color: $hover-color; // Für Dark-Mode spezifisch
      }
    }
  }  

  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(8px); // Hintergrund wird geblurrt
    z-index: 1000;

    .popup-content {
      background: $container-bg-light; // Standard für Light-Mode
      color: $font-color-light;
      border-radius: 12px;
      padding: 2em;
      max-width: 600px;
      width: 90%;
      box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4);
      position: relative;
      animation: popup-fade-in 0.3s ease;

      &.dark-mode {
        background: $container-bg-dark; // Für Dark-Mode
        color: $font-color-dark;
        border-color: #444;
      }

      .close-button {
        position: absolute;
        top: 1em;
        right: 1em;
        background: transparent;
        border: none;
        font-size: 1.5em;
        font-weight: bold;
        cursor: pointer; // Zeigt an, dass es anklickbar ist
        transition: color 0.2s ease;
      
        &:hover {
          color: $primary-color; // Hover-Farbe ändern
          transform: scale(1.1); // Leichte Vergrößerung
        }
      }      
    }
  }

  // Für den Blur-Effekt, wenn das Popup aktiv ist
  &.blur-active {
    filter: blur(8px);
    pointer-events: none; // Verhindert, dass der Hintergrund interaktiv ist
  }
}

// Animation für das Popup
@keyframes popup-fade-in {
  from {
    transform: scale(0.95);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

// Light-Mode spezifische Anpassungen
.light-mode {
  .tip-card {
    background: $container-bg-light;
    color: $font-color-light;
  }

  .popup-content {
    background: $container-bg-light;
    color: $font-color-light;
  }
}

// Dark-Mode spezifische Anpassungen
.dark-mode {
  .tip-card {
    background: $container-bg-dark;
    color: $font-color-dark;
  }

  .popup-content {
    background: $container-bg-dark;
    color: $font-color-dark;
  }
}
